<template>
  <div class="main_container">
    <Event
        :event="state.selectedEvent"
        @close="() => state.selectedEvent = null"
    />

    <Typography variant="title" class="title">
      Лента Мероприятий
    </Typography>

    <Typography variant="body1" color="#A6A5A5">
      События, встречи и анонсы, которые ты не можешь пропустить
    </Typography>
  </div>

  <div class="main_container" style="padding: 0; margin-top: 12px; overflow: hidden">
    <div class="main_container">
      <swiper
          v-if="state.eventsList === null"
          :allowSlideNext="false"
          :allowSlidePrev="false"
          :slidesPerView="'auto'"
          :spaceBetween="8"
          :freeMode="true"
          class="event_swiper"
      >
        <swiper-slide class="slide">
          <EventCard
              :is-loading="true"
          />
        </swiper-slide>
        <swiper-slide class="slide">
          <EventCard
              :is-loading="true"
          />
        </swiper-slide>
      </swiper>
      <Card
          v-else-if="state.eventsList.length === 0"
      >
        <Icon
            width="100%"
            height="138"
            :icon="require(`@/assets/images/events/emptyEvents.png`)"
            style="background-size: contain; margin-bottom: 16px"
        />
        <Typography variant="subtitle3" color="#A6A5A5" center style="margin: 0 32px; min-height: 40px">
          Новые мероприятия будут совсем скоро
        </Typography>
      </Card>
      <swiper
          v-else
          :slidesPerView="'auto'"
          :spaceBetween="8"
          :freeMode="true"
          class="event_swiper"
          @slideChange="slideChange"
      >
        <swiper-slide v-for="event in state.eventsList" class="slide">
          <EventCard
              :data="event"
              @click="openEvent(event)"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup>
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import {onBeforeMount, reactive} from 'vue'
import {appAxios} from '@/axios'
import Card from '@/components/UI/Card.vue'
import Event from '@/views/HomeView/Event.vue'
import EventType from '@/views/HomeView/EventType.vue'
import moment from 'moment/moment'
import {monthNames} from '@/configs/monthNames'
import Skeleton from '@/components/UI/Skeleton.vue'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import {router} from '@/router/router.js'
import {Swiper, SwiperSlide} from 'swiper/vue'
import UserCard from '@/components/UserCard.vue'
import EventCard from '@/views/HomeView/EventCard.vue'
import Icon from "@/components/UI/Icon.vue";
import {yandexMetrika} from "@/external/yandexWebisor.js";

const state = reactive({
  isLoading: true,
  eventsList: null,
  selectedEvent: null,
  swipedEventFired: false,
})

async function openEvent(event) {
  state.selectedEvent = event
  await yandexMetrika('reachGoal', 'eventOpen', {
    title: event.title,
  })
}

onBeforeMount(async () => {
  try {
    const res = await appAxios.events.fetchAll()

    const eventsList = res.data
    state.eventsList = eventsList.sort(function (a, b) {
      return new Date(a.start_date) - new Date(b.start_date)
    })

    for (const event of state.eventsList) {
      const {start_date, end_date} = event
      const start = moment(start_date, 'YYYY-MM-DD')
      const end = moment(end_date, 'YYYY-MM-DD')

      if (start.year() === end.year() && start.month() === end.month() && start.day() === end.day()) {
        event.dateSting = `${start.format('DD.MM')}`
      } else if (start.year() === end.year() && start.month() === end.month()) {
        event.dateSting = `${start.format('DD.MM')} - ${end.format('DD.MM')}`
      } else {
        event.dateSting = `${start.format('DD.MM')} - ${end.format('DD.MM')}`
      }
    }
  } catch (e) {
    console.error(e)
    // todo: error?
  } finally {
    state.isLoading = false
  }
})

async function slideChange() {
  if(!state.swipedEventFired) {
    state.swipedEventFired = true
    await yandexMetrika('reachGoal', 'eventsSwiped')
  }
}

</script>

<style lang="scss" scoped>

.title {
  margin: 12px 0 4px;
}

.event_swiper {
  overflow: visible;
  height: 224px;
}

.slide {
  width: calc(70%);
  min-width: 290px;
}

</style>
